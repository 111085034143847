<!--
 * @Author: your name
 * @Date: 2022-04-07 09:22:38
 * @LastEditTime: 2022-04-11 16:52:44
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\mealbox\index.vue
-->
<template>
  <div class="item" :class="{selitem:item.id == active?true:false}" @click="selectitem"><span class="span">{{item.money}}</span>元</div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props:{
    item:{
      typeof:Object,
      default:()=>{
        return {}
      }
    },
    active:{
      type:Number
    }
  },
  methods:{
    selectitem(){
      this.$emit("selectitem",this.item)
    }
  }
}
</script>
<style lang="less" scoped>
.item{
    width: 105px;
    height: 54px;
    border-radius: 6px;
    border: solid #8eb4f4 1px;
    text-align: center;
    line-height: 54px;
    color: #1a6af1;
    font-size: 16px;
    margin-bottom: 15px;
    .span{
      font-family: "bahn";
    }
}
.item:nth-child(1),.item:nth-child(2),.item:nth-child(4),.item:nth-child(5){
  margin-right: 10px;
}
.selitem{
  background-color: #1a6af1;
  color: #fff;
}
</style>
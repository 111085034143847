<!--
 * @Author: your name
 * @Date: 2022-04-06 17:54:17
 * @LastEditTime: 2022-06-16 09:12:10
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\recharge\index.vue
-->
<template>
  <div class="recharge">
    <div class="center">
      <div class="info">
        <p class="pone">设备余额</p>
        <div class="banlac">
          <div class="banlac-box">{{info.c_balance}}<span class="span">元</span></div>
        </div>
        <div class="accnum">
          <div class="ptwo">充值设备号</div>
          <div class="acc">{{info.iccid}}</div>
        </div>
      </div>
      <div class="title">充值金额</div>
      <div class="mealbox">
        <mealbox v-for="(item,index) in meallistbox" :key="index" :item="item" :active="active" @selectitem="selectitem($event)"></mealbox>
      </div>
      <p class="curuser">{{info.buy_name}}</p>
      <paymode @change="paychange($event)" v-if="!weixin"></paymode>
      <div class="paybox" v-else>
        <van-radio-group v-model="payradio">
          <van-cell-group>
            <van-cell clickable @click="payradio = '1'">
              <template #title>
                <div class="radiobox">
                  <img src="@/assets/images/recharge/wechat.png" alt="" class="img">
                  <div class="text">微信支付</div>
                </div>
              </template>
              <template #right-icon>
                <van-radio name="1" checked-color="#f11a52" />
              </template>
          </van-cell>
      </van-cell-group>
    </van-radio-group>
      </div>
      <van-button class="paybutton" @click="paybutton" loading-text="加载中..." :loading="loading">立即充值</van-button>
    </div> 
     <querytoastbox :show="queryShow" @clkleft="queryShow = false" @clkright="clkQuqeryRight">
      <template #center>
      <div class="querycenter">即将打开"锐wifi"小程序</div>
      </template>
    </querytoastbox>
  </div>
</template>
<script>
import isAli from "@/untils/isAli"
import querytoastbox from "@/components/querytoastbox/index.vue"
import isweixin from "@/untils/isweixin.js"
import paymode from "@/components/paymode/index.vue"
import mealbox from "@/components/mealbox/index.vue"
import paymixin from "@/mixin/paymixin.js"
export default {
  data() {
    return {
       queryShow:false,  // 是否显示缺人前往微信小程序
      payradio:"1",  // 选中的支付方式 1是微信2是支付宝
      meallistbox:[], // 充值金额数组
      active:1, // 默认选中哪一个
      activeItem:{}, // 选中的值
      info:{},  // 详细信息
      loading:false, // 支付按钮是否处于加载状态
      weixin:false,  // 是否在微信环境中
    }
  },
  // 在dom树没有完全生成之前 判断是不是微信环境
  created(){
    this.weixin = isweixin()
  },
  mounted(){
    if(this.$route.query.openid){
      window.sessionStorage.setItem("openid",this.$route.query.openid)
    }
    this.getList()
    // 如果是支付宝环境
    if(isAli()){
      this.payradio = "2"
    }
  },
  methods:{
    clkQuqeryRight(){
      // 点击了确定前往微信小程序
      this.jumpApplet()
    },
    // 支付方式发生了改变
    paychange($event){
      this.payradio = $event
    },
     // 跳往微信小程序
    jumpApplet(){
      if((!this.weixin) && this.payradio == 1 ){
        let iccid = window.sessionStorage.getItem("iccid")
        let data ={
          iccid:iccid,
          type:2
        }
        this.getUrl(data)
      }
      return false
    },
    // 支付按钮
    paybutton(){
      if((!this.weixin) && this.payradio == 1){
        this.queryShow = true
        return false
      }
      this.loading = true
      let data ={
        price:this.activeItem.money, // 金额
        iccid:this.info.iccid,  // 套餐设备id
        phone:sessionStorage.getItem("phone"),  //手机号
        type:3,  //购买类型
        buy_id:-1,  // 购买id
        platform:1, // 1是h5
        openid:sessionStorage.getItem("openid"),  // 微信openid
        pay_type:this.payradio == 1?1:5  // 1是微信 2是H5
      }
      this.setpayinfo(data)
    },
    // 选中设置
    selectitem($event){
      this.active = $event.id
      this.activeItem = $event
    },
    // 获取页面信息
    getList(){
      let data ={
        iccid:sessionStorage.getItem("iccid")
      }
      this.getRechargeList({data}).then(res =>{
        this.meallistbox = res.data.list
        this.info = res.data.info
        this.activeItem = this.meallistbox[0]
        this.active = this.meallistbox[0].id
      })
    }
  },
  components:{
    mealbox,
    paymode,
    querytoastbox
  },
  mixins:[paymixin]
}
</script>
<style lang="less" scoped>
.recharge{
  width: 100%;
  .center{
    width: 92%;
    margin:  0 auto;
    .info{
      width: 100%;
      border-bottom: #ecedee solid 1px;
      .pone{
        font-size: 13px;
        color: #969ca4;
        padding-top: 24px;
      }
      .banlac{
        display: flex;
        flex-direction: row-reverse;
        margin: 20px 0;
        .banlac-box{
          color: #f11a52;
          font-size: 36px;
          font-family: "bahn";
          .span{
            font-size: 18px;
          }
        }
      }
      .accnum{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .ptwo{
          font-size: 13px;
          color: #969ca4;
        }
        .acc{
          color: #333333;
          font-size: 14px;
          font-family: "bahn";
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }
    .title{
      font-size: 16px;
      color: #222222;
      font-weight: 600;
      margin: 24px 0;
    }
    .mealbox{
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .curuser{
      font-size: 14px;
      color: #777777;
      margin-top: 8px;
      margin-bottom: 30px;
    }
    .paybox{
      min-width: 100%;
      min-height: 54px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 74px;
      /deep/.van-radio-group{
        height: 54px;
        .van-cell--clickable{
          height: 54px;
          align-items: center;
          justify-items: center;
          background-color: #eceff3;
        }
        .van-cell--clickable:active{
          background-color: #cecece;
        }
      }
      .radiobox{
        display: flex;
        justify-items: center;
        align-items: center;
        .img{
          height: 18px;
          display: block;
          margin-right: 4px;
        }
        .text{
          font-size: 14px;
          color: #333333;
          font-weight: 600;
        }
      }
    }
    .paybutton{
      background-color: #3191f3;
      color: #fff;
      width: 240px;
      height: 48px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      margin: 0 auto;
      font-size: 16px;
      text-align: center;
      line-height: 48px;
      font-weight: 600;
      display: block;
    }
  }
  .querycenter{
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
/*
 * @Author: your name
 * @Date: 2022-04-11 08:43:38
 * @LastEditTime: 2022-06-15 13:58:20
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\mixin\paymixin.js
 */
import isweixin from "@/untils/isweixin.js"
let paymixin ={
  methods:{
    setpayinfo(data){
      this.getpayinfo({data}).then(res =>{
        if(res.data.code == 1){
          // 返回成功的支付参数
          if(isweixin()){
            // 如果是在微信环境下
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  this.wxpay(res.data.info),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  this.wxpay(res.data.info)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  this.wxpay(res.data.info)
                );
              }
            } else {
              this.wxpay(res.data.info)
            }
          }else{
            this.prohibit = false
            this.isloading = true
            this.loading = false
            this.reshow = false
            // console.log(res.data.info)
            let url = res.data.info
            setTimeout(()=>{
              this.alipayshow = true
            },1000)
            window.location.href=url
          }
        }else if (res.data.code == 0){
          // 支付参数错误
          this.$toast(`${res.data.msg}`)
          this.prohibit = false
          this.reshow = false
          this.isloading = true
          this.loading = false
        }else if (res.data.code == 2){
          // 余额充足
          this.$toast(`${res.data.msg}`)
          this.prohibit = false
          this.reshow = false
          this.isloading = true
          this.loading = false
          setTimeout(() => {
            this.$router.push("buysuccess?is_first="+this.is_first+"&iccid="+this.iccid)
          }, 1500);
        }else if (res.data.code == 3){
          // 余额不足
          this.$toast(`${res.data.msg}`)
          this.prohibit = false
          this.reshow = true
          this.isloading = true 
          this.loading = false     
        }
      })
    },
    // 掉起微信支付
    wxpay(info){
      let that = this
      that.prohibit = false
      that.reshow = false
      that.isloading = true
      that.loading = false
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": info.appId,
                "timeStamp": info.timeStamp,
                "nonceStr": info.nonceStr,
                "package": info.package,
                "signType": info.signType,
                "paySign": info.paySign
            },
            function(res) {
                console.log(res.err_msg);
                if (res.err_msg == 'get_brand_wcpay_request:ok') {
                    // window.location.href="buy_success.html?oid="+oid;
                     //alert("充值成功");
                    that.$toast.success("支付成功")
                    that.prohibit = false
                    that.reshow = false
                    that.isloading = true
                    that.loading = false
                }else {
                    // alert('您已经取消支付');
                    that.$toast("您已经取消支付")
                    that.prohibit = false
                    that.reshow = false
                    that.isloading = true
                    that.loading = false
                    //window.location.reload();
                }
            });
    },
    // 获取到小程序内的URL链接
    getUrl(params){
    
      let data = {
        iccid:params.iccid,
        type:params.type
      }
      this.getUrlShare({data}).then(res =>{
        if(res.data.code == 1){
          this.queryShow = false
          this.prohibit = false
          this.isloading = true
          this.loading = false
          this.reshow = false
          window.location.href = `${res.data.info.urlscheme}`
        }
        if(res.data.code == 0){
          this.queryShow = false
          this.$toast("网络异常，请稍后充值~")
        }
      })
    }
  }
}
export default paymixin
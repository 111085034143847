<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-08 15:34:22
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-15 15:19:35
 * @FilePath: \newwifisvn\src\components\toastbox\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <van-overlay :show="show">
      <div class="querybox" @click.stop>
       <slot name="center"></slot>
        <div class="bottom" v-if="!isnewButtm">
          <div class="bottom-left" @click="clkleft">{{bottomInfo.left}}</div>
          <div class="bottom-right" @click="clkright">{{bottomInfo.right}}</div>
        </div>
        <div class="newbottom" v-else @click="clknew">
           {{newtext}}
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props:{
    show:{
      type:Boolean,
      default:false
    },
    bottomInfo:{
      type:Object,
      default:()=>{
        return{
          left:"取消",
          right:"允许"
        }
      }
    },
    // 是否展现单个按钮的底部
    isnewButtm:{
      type:Boolean,
      default:false
    },
    newtext:{
      type:String,
      default:"确认"
    }
  },
  methods:{
    // 点击了左边的按钮
    clkleft(){
      this.$emit("clkleft")
    },
    // 点击了右边的按钮
    clkright(){
      this.$emit("clkright")
    },
    // 只有一个按钮的时候，那个按钮被点击了
    clknew(){
      this.$emit("clknew")
    }
  }
}
</script>
<style lang="less" scoped>
  /deep/.van-overlay{
    z-index: 9;
    .querybox{
      width: 70%;
      height: 120px;
      background-color: #ffffff;
      border-radius: 10px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      .bottom{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        display: flex;
        border-top:solid 1px #f9f9f9;
        .bottom-left{
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          border-right: solid 1px #f9f9f9;
          line-height: 40px;
          height: 40px;
          flex-basis: 50%;
        }
        .bottom-right{
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          line-height: 40px;
          height: 40px;
          flex-basis: 50%;
          color: #000000;
        }
      }
      .newbottom{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        display: flex;
        border-top:solid 1px #f9f9f9;
        justify-content: center;
        font-size: 16px;
        letter-spacing: 4px;
        font-weight: 600;
        justify-items: center;
        align-items: center;
      }
    }
  }
</style>

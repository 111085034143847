<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-07 16:42:57
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-16 09:28:23
 * @FilePath: \newwifisvn\src\views\selectpack\components\paymode\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="payradiobox">
        <van-radio-group v-model="payradio" @change="clkchange">
          <van-cell-group v-for="item in radioObj" :key="item.radio" @click="payradio = item.radio">
            <van-cell clickable>
              <template #title>
                <div class="radiobox">
                  <img :src="item.img" alt="" class="img">
                  <div class="text">{{item.text}}</div>
                </div>
              </template>
              <template #right-icon>
                <van-radio :name="item.radio" checked-color="#f11a52" />
              </template>
          </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>
<script>
import isAli from '@/untils/isAli'
export default {
  data() {
    return{
      payradio:"1",
      radioObj:[
        {
           text:"微信支付",
           radio:"1",
           img:require("@/assets/images/recharge/wechat.png")
        },
        {
           text:"支付宝支付",
           radio:"2",
           img:require("@/assets/images/selectpack/alipay.png")
        }
      ],  // 支付方式数组
    }
  },
  mounted(){
    this.setAli()
  },
  methods:{
    // 如果是在支付宝环境内
    setAli(){
      if(isAli()){
        this.payradio = "2"
        this.radioObj.shift()
      }
    },
    // 选中的值发生了变化
    clkchange(name){
      this.$emit("change",name)
    }
  }
}
</script>
<style lang="less" scoped>
.payradiobox{
      min-width: 100%;
      min-height: 54px;
      border-radius: 10px;
      overflow: hidden;
      padding-bottom: 140px;
      /deep/.van-radio-group{
        height: 54px;
        .van-cell-group {
          margin-bottom: 16px;
        }
        .van-cell--clickable{
          height: 54px;
          align-items: center;
          justify-items: center;
          background-color: #fff;
        }
        .van-cell--clickable:active{
          background-color: #fff;
        }
      }
      .radiobox{
        display: flex;
        justify-items: center;
        align-items: center;
        .img{
          height: 18px;
          display: block;
          margin-right: 4px;
        }
        .text{
          font-size: 14px;
          color: #333333;
          font-weight: 600;
        }
      }
}
</style>